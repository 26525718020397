Payment

<template>
  <div class="mt-10">
    <v-row class="text-center" v-if="!isPaid">
      <v-col cols="12">
        <v-card
          class="mx-auto"
          style="max-width: 700px; height: auto; width: 95%"
        >
          <v-card-title class="d-flex justify-center">
            <span>กรุณาชำระและแจ้งโอนภายใน</span>
          </v-card-title>
          <v-card-title class="d-flex justify-center">
            <span style="font-size: 40px; color: #ff0000"
              >{{ formatTime(countdown) }} นาที</span
            >
          </v-card-title>
          <v-row class="pl-8 pr-8">
            <v-col cols="8" class="text-start">
              จำนวน {{ setAmtAll }} ใบ
            </v-col>
            <v-col cols="4" class="text-end">
              <span>{{ setAmtAll * 80 }}.00 บาท</span>
            </v-col>
          </v-row>
          <v-row class="mt-n4 pl-8 pr-8">
            <v-col cols="8" class="text-start">
              ค่าบริการ {{ setAmtAll }} ช่อง
            </v-col>
            <v-col cols="4" class="text-end">
              <!-- <span>{{ setAmtAll * this.customPrice }} บาท</span> -->
              <span>{{ totalPriceService }} บาท</span>
            </v-col>
          </v-row>
          <v-row class="mt-n4 pl-8 pr-8">
            <v-col cols="8" class="text-start">
              <span style="font-weight: bold">ยอดรวมทั้งสิ้น</span>
            </v-col>
            <v-col cols="4" class="text-end">
              <!-- <span style="font-weight: bold">{{ totalPrice }}.00 บาท</span> -->
              <span style="font-weight: bold">{{ totalPriceAll }}.00 บาท</span>
            </v-col>
          </v-row>

          <v-carousel
            :style="
              $vuetify.breakpoint.smAndUp ? 'height: 300px' : 'height: 220px'
            "
            :continuous="false"
          >
            <v-carousel-item
              v-for="(item, index) in carouselItems"
              :key="index"
              cover
            >
              <v-card>
                <img
                  :src="item.urlImage"
                  style="width: 100%; height: 100%; object-fit: cover"
                  alt="Carousel Item"
                />
              </v-card>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-col>

      <v-col cols="12" class="">
        <v-card
          class="mx-auto pa-4"
          style="max-width: 700px; height: auto; width: 95%"
        >
          <v-card-title class="d-flex justify-start">
            <span>วิธีการชำระเงิน</span>
          </v-card-title>
          <v-card-title class="d-flex justify-center">
            <span style="font-weight: bold">ยอดที่ต้องชำระ</span>&nbsp;
            <!-- <span style="font-weight: bold; color: red"
              ><u>{{ totalPrice }}.00 บาท</u>
            </span> -->
            <span style="font-weight: bold; color: red"
              ><u>{{ totalPriceAll }}.00 บาท</u>
            </span>
          </v-card-title>

          <v-tabs v-model="selectTabs" fixed-tabs>
            <v-tabs-slider></v-tabs-slider>
            <!-- <v-tab :class="{ 'highlighted-tab': selectTabs === 0 }">
              <span :style="selectTabs === 0 ? 'color: #fff' : ''"
                >สแกน QR Code</span
              >
            </v-tab> -->
            <v-tab :class="{ 'highlighted-tab': selectTabs === 0 }">
              <span :style="selectTabs === 0 ? 'color: #fff' : ''"
                >โอนผ่านธนาคาร</span
              >
            </v-tab>
          </v-tabs>

          <!-- <div v-if="selectTabs === 0">
            <div>
              หากสแกนโอนเงินเรียบร้อยโปรดคลิกปุ่ม
              <span style="color: red">ชำระเงิน</span>
            </div>
            <div>QR Code</div>
            <div>มังกรมหาบูรพา</div>
          </div> -->
          <div v-if="selectTabs === 0">
            <span style="font-size: 24px"> แนบสลิป </span> <br />
            <span style="font-size: 14px">
              หากโอนเงินเรียบร้อยแล้วโปรดแนบสลิปและคลิกปุ่ม
            </span>
            <br />
            <span style="font-size: 14px; color: red">
              "ยืนยันการโอนเงิน"
            </span>
            <br /><br />
            <v-row>
              <v-col cols="12">
                <v-img
                  width="50px"
                  height="50px"
                  src="../assets/logo/kbankLogo.jpeg"
                  alt="logoDragon"
                  class="mx-auto"
                  style="max-width: 100%; border-radius: 6px"
                />
              </v-col>
              <v-col cols="12" class="text-center">
                <span style="font-size: 20px">
                  ธนาคาร: ธนาคารกสิกรไทย KBANK
                </span>
                <br />
                <span style="font-size: 20px">
                  ชื่อบัญชี: บจก. มังกรฟ้า มหาบูรพา
                </span>
              </v-col>

              <v-col cols="12" class="text-center">
                <span style="font-size: 28px">
                  เลขที่บัญชี: 137-2-65602-2
                </span>
              </v-col>
              <v-col cols="12" class="text-center mt-n6">
                <v-btn
                  text
                  outlined
                  style="border-color: #4ca3ad"
                  @click="copyBankNo()"
                >
                  <span style="font-size: 20px; color: #4ca3ad"> คัดลอก </span>
                  <v-icon style="color: #4ca3ad"
                    >mdi-clipboard-multiple-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <v-card outlined class="ma-4">
            <v-row no-gutters align="center" justify="center">
              <v-col cols="12" class="pt-6">
                <span>อัพโหลดรูปสลิป</span>
                <span style="color: red">*</span>
              </v-col>
              <v-col cols="11" class="mt-5 ma-10" id="step-3">
                <v-row align="center" justify="center">
                  <v-btn
                    @click="changePic1()"
                    text
                    outlined
                    color="white"
                    style="height: 80px; border-color: #4ca3ad"
                  >
                    <v-img
                      v-model="picture_1"
                      src="@/assets/logo/Logo1.jpg"
                      class="mr-3"
                      max-width="70"
                    >
                    </v-img>
                    <span style="color: black; font-size: 20px"
                      >แนบสลิปที่นี่</span
                    >
                  </v-btn>
                </v-row>
                <v-row align="center" justify="center" class="mt-4">
                  <v-col cols="12" md="12">
                    <v-card dense light class="pa-1" v-if="showImage">
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-icon small light @click="RemoveImage()"
                          >mdi-close</v-icon
                        >
                      </v-card-actions>

                      <v-img
                        :src="showImage"
                        max-width="400"
                        class="mx-auto"
                        aspect-ratio="0.9"
                        contain
                      ></v-img>
                    </v-card>
                  </v-col>
                </v-row>
                <input
                  type="file"
                  ref="image"
                  id="picTure1"
                  accept="*/*"
                  @change="showPicture1($event)"
                  style="display: none"
                  hidden
              /></v-col>
            </v-row>
          </v-card>

          <!-- <v-row align="center" justify="center" class="mb-4">
            <v-btn
              @click="checkPaid()"
              style="background: red"
              :disabled="!showImage"
            >
              <span style="color: white">ชำระเงิน / อัพโหลดสลิป</span>
            </v-btn>
          </v-row> -->

          <!-- <v-row class="">
            <v-col cols="12">
              <v-btn
                v-if="selectTabs === 0"
                @click="checkPaid()"
                block
                color="#2c2c2c"
                ><span style="color: #fff">ยืนยันการโอนเงิน</span>
              </v-btn>
            </v-col>
          </v-row> -->
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Encode, Decode } from "@/services";
export default {
  components: {},

  data: () => ({
    countdown: 0,
    timerInterval: null,
    selectTabs: 0,
    setAmtAll: 0,
    pic1: [],
    picture_1: "",
    showImage: "",
    imageName1: "",

    selectedItems: [
      // { lotteryNo: "111111", setAmt: 1 },
      // { lotteryNo: "222222", setAmt: 2 },
      // { lotteryNo: "333333", setAmt: 3 },
      // { lotteryNo: "333333", setAmt: 10 },
    ],

    isPaid: false,
    bankNo: 1372656022,

    totalPrice: 0,
    trasactionId: null,
    userData: {},

    customPrice: 0,
    customPrice1: 0,
    customPrice2: 0,
    customPrice10: 0,
    totalPriceService: 0,
    totalPriceAll: 0,
  }),

  watch: {
    showImage(val) {
      if (val) {
        this.checkPaid();
      }
    },
  },
  computed: {
    carouselItems() {
      return this.selectedItems.flatMap((item) =>
        Array(item.setAmt).fill(item)
      );
    },
  },

  async created() {
    const encodedUserLogin = localStorage.getItem("MAHAUser");
    if (encodedUserLogin !== null) {
      const decodedUserLogin = Decode.decode(encodedUserLogin);
      this.userData = JSON.parse(decodedUserLogin);
    }

    const storedItems = localStorage.getItem("paymentItems");
    this.selectedItems = storedItems ? JSON.parse(storedItems) : [];

    await this.calculateSetAmtAll();
    await this.getCustomPrice();
    await this.calculateTotalPrice();
    await this.calculateParam();
  },

  mounted() {
    this.initializeCountdown();
    this.startCountdown();
  },

  beforeDestroy() {
    this.stopCountdown();
  },

  methods: {
    async calculateTotalPrice() {
      this.selectedItems.forEach((element) => {
        if (element.setAmt < 3) {
          this.totalPriceService += element.setAmt * this.customPrice;
          this.totalPriceAll += element.setAmt * (80 + this.customPrice);
        } else if (element.setAmt == 3) {
          // this.totalPriceService += element.setAmt * this.customPrice1;
          this.totalPriceService += 110;
          // this.totalPriceAll += element.setAmt * (80 + this.customPrice1);
          this.totalPriceAll += 350;
        } else if (element.setAmt == 10) {
          this.totalPriceService += element.setAmt * this.customPrice10;
          this.totalPriceAll += element.setAmt * (80 + this.customPrice10);
        } else {
          this.totalPriceService += element.setAmt * this.customPrice2;
          this.totalPriceAll += element.setAmt * (80 + this.customPrice2);
        }
      });
    },
    async getCustomPrice() {
      const responseCustomPirce = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/CUSTOM_PRICE`
      );
      this.customPrice = parseInt(responseCustomPirce.data.data.value1);
      // const responseCustomPirce1 = await this.axios.get(
      //   `${process.env.VUE_APP_API}/configs/findByType/AGENT_PRICE_5`
      // );
      // this.customPrice1 = parseInt(responseCustomPirce1.data.data.value1);
      const responseCustomPirce2 = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/CUSTOM_PRICE_2`
      );
      this.customPrice2 = parseInt(responseCustomPirce2.data.data.value1);

      const responseCustomPirce10 = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/CUSTOM_PRICE_10`
      );
      this.customPrice10 = parseInt(responseCustomPirce10.data.data.value1);
    },
    startCountdown() {
      this.timerInterval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.stopCountdown();
          localStorage.removeItem("paymentDatetime");
          this.$swal.fire({
            icon: "error",
            title: "หมดเวลาตะกร้า",
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(() => {
            this.$router.push("/");
          }, 500);
        }
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.timerInterval);
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${
        remainingSeconds < 10 ? "0" : ""
      }${remainingSeconds}`;
    },
    initializeCountdown() {
      const paymentDatetime = localStorage.getItem("paymentDatetime");
      if (paymentDatetime) {
        const storedTime = new Date(paymentDatetime).getTime();
        const currentTime = new Date().getTime();
        const differenceInSeconds = Math.floor(
          (currentTime - storedTime) / 1000
        );
        this.countdown = Math.max(3600 - differenceInSeconds, 0);
      } else {
        this.countdown = 0;
      }
    },

    async checkPaid() {
      this.$swal({
        text: "ยืนยันการจอง/ส่งหลักฐานการโอนเงิน ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ยืนยัน",
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (this.totalPrice == 0) {
            this.$swal.fire({
              icon: "error",
              title: "ไม่มีสินค้าในตะกร้า",
              showConfirmButton: false,
              timer: 1500,
            });
            localStorage.removeItem("paymentDatetime");
            this.$router.push("/");
          } else if (this.totalPrice !== 0) {
            await this.uploadSlip();
          }
        } else {
          this.RemoveImage();
        }
      });
    },

    async uploadSlip() {
      var user = JSON.parse(Decode.decode(localStorage.getItem("MAHAUser")));
      this.trasactionId = localStorage.getItem("trasactionId");
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const responseCheck = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/` + this.trasactionId,
        auth
      );
      if (
        responseCheck.data.data.status == "ยกเลิก" ||
        responseCheck.data.data.status == "SUCCESS"
      ) {
        await this.$swal.fire({
          icon: "warning",
          html: "รายการนี้ถูกยกเลิกหรือสำเร็จไปแล้ว<br/>ไม่สามารถอัพโหลดสลิปได้อีก",
          showConfirmButton: false,
          timer: 3000,
        });
        this.$router.push("/");
      } else {
        const data = {
          status: "รอดำเนินการ",
          slipImg: this.pic1,
        };

        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/transaction/update/` + this.trasactionId,
          data,
          auth
        );

        if (response.data.response_status == "ERROR") {
          this.$swal.fire({
            icon: "warning",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          this.$swal.fire({
            icon: "success",
            // title: response.data.message,
            title: "อัพโหลดสลิปสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          });
          this.isPaid = true;
          this.$router.push("/order");
          localStorage.setItem("tSuccess", encode(this.trasactionId));
          localStorage.removeItem("trasactionId", this.trasactionId);
        }
      }
    },
    showPicture1(e) {
      //
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          //
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    RemoveImage(index, item) {
      //
      // if (item.id !== "-1") {
      //   this.Remove_img.push({
      //     id: item.id,
      //   });
      // }
      // this.product_image.splice(index, 1);
      this.pic1 = [];
      this.picture_1 = "";
      this.showImage = "";
      this.imageName1 = "";
    },

    copyBankNo() {
      navigator.clipboard.writeText(this.bankNo);
      this.$swal.fire({
        icon: "success",
        title: "คัดลอกสำเร็จ",
        showConfirmButton: false,
        timer: 1000,
      });
    },

    calculateParam() {
      this.totalPrice = this.setAmtAll * (80 + this.customPrice);
    },
    calculateSetAmtAll() {
      this.setAmtAll = this.selectedItems.reduce(
        (total, item) => total + item.setAmt,
        0
      );
    },
  },
};
</script>

<style scoped>
.single-line-row {
  display: flex;
  align-items: center;
}

.custom-link {
  color: black;
  transition: color 0.3s; /* Add a smooth transition effect */
}

.custom-link:hover {
  color: #4ca3ad; /* Change to your primary color */
}

.highlighted-tab {
  background-color: #4ca3ad;
}
</style>



